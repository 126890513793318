// Use apollo vue useQuery and make it blocking

import type { ApolloQueryResult, OperationVariables } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { useQuery } from '@vue/apollo-composable';
import type {
  DocumentParameter,
  OnResultContext,
  VariablesParameter,
} from '@vue/apollo-composable/dist/useQuery.js';

// Adds messages only in a dev environment, see https://www.apollographql.com/docs/react/errors/#what-is-this-page
if (process.env.NODE_ENV !== 'production') {
  loadDevMessages();
  loadErrorMessages();
}

export default <
  TResult = never,
  TVariables extends OperationVariables = OperationVariables,
>(
  document: DocumentParameter<TResult, TVariables>,
  variables?: VariablesParameter<TVariables>,
): Promise<ApolloQueryResult<TResult>> => {
  return new Promise((resolve, reject) => {
    const { onResult, onError } = useQuery<TResult, TVariables>(
      document,
      variables,
      {
        // TODO: Fix SSR loading issues
        fetchPolicy: 'no-cache',
      },
    );

    onResult((result: ApolloQueryResult<TResult>, context: OnResultContext) => {
      resolve(result);
    });

    onError((error) => {
      reject(error);
    });
  });
};
